<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="() => $router.back()">
            <ion-icon :icon="chevronBackOutline"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>Skupina</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <GroupCard
        :id="getGroupDetail.id"
        :name="getGroupDetail.name"
        :members="getGroupDetail.count_members"
        :isMember="getGroupDetail.is_member"
        :image="getGroupDetail.media"
      />
      <ion-list lines="none" class="ion-padding">
        <template v-if="getActivePosts.length == 0">
          <ion-item>
            <ion-label style="text-align: center;">
              <h2>Tato skupina nemá žádný příspěvek.</h2>
              <p>Přidej první.</p>
            </ion-label>
          </ion-item>
        </template>
        <template v-else>
          <Post
            v-for="item in getActivePosts"
            :key="item.id"
            :avatar="item.avatar_url"
            :name="item.name"
            :id="item.id"
            :time="item.created_at"
            :text="item.text"
            :likes="item.likes"
            :isLiked="item.is_liked"
            :comments="item.comments"
            :numOfComments="item.comments.length"
            :postImg="item.media[0]"
            :groupId="getGroupDetail.id"
          />
        </template>
      </ion-list>
    </ion-content>
    <ion-fab vertical="bottom" horizontal="end">
      <ion-fab-button @click="createNewPost()">
        <ion-icon :icon="pencil"></ion-icon>
      </ion-fab-button>
    </ion-fab>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonList,
  IonFooter,
  IonTitle,
  IonToolbar,
  IonHeader,
  IonButtons,
  IonLabel,
  IonItem,
  IonTextarea,
  modalController,
  IonFab,
  IonFabButton,
} from "@ionic/vue";
import { mapActions, mapGetters } from "vuex";
import {
  chevronBackOutline,
  sendOutline,
  addOutline,
  pencil,
} from "ionicons/icons";
import GroupCard from "../components/GroupCard.vue";
import NewPostModal from "../components/NewPostModal.vue";
import Post from "../components/Post.vue";

export default {
  name: "GroupDetail",
  data() {
    return {
      id: "",
      event: {},
    };
  },
  components: {
    IonPage,
    IonContent,
    IonIcon,
    IonButton,
    IonButtons,
    IonTitle,
    IonToolbar,
    IonHeader,
    IonList,
    GroupCard,
    IonLabel,
    IonItem,
    IonFab,
    IonFabButton,
    Post,
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
      getGroupDetail: "groups/getGroupDetail",
      getActivePosts: "social/getActivePosts",
    }),
  },
  watch: {
    messageText(value) {
      this.messageText = value;
      // console.log("val", this.messageText, this.canBePosted);
      if (this.messageText.length > 0) {
        this.canBePosted = true;
      } else {
        this.canBePosted = false;
      }
    },
  },
  created() {},
  beforeMount() {
    this.names = "";
    this.id = this.$route.params.id;
    this.downloadGroupDetail({
      networkId: this.getActiveId,
      groupId: this.id,
    }).then((res) => {
      // this.event = this.getEventDetail;
      //   console.log("res");
    });
  },
  mounted() {},
  methods: {
    ...mapActions({
      downloadGroupDetail: "groups/downloadGroupDetail",
    }),
    async createNewPost() {
      let self = this;
      const modal = await modalController.create({
        component: NewPostModal,
        cssClass: "post-modal",
        componentProps: {
          eventId: 0,
          groupId: this.id,
        },
      });

      modal.present();

      modal.onDidDismiss().then((data) => {
        // self.loadPosts();
      });
    },
  },
  setup() {
    return {
      chevronBackOutline,
      sendOutline,
      addOutline,
      pencil,
    };
  },
};
</script>

<style scoped>
ion-card {
  border-radius: 0;
}
</style>
