<template>
  <ion-card :class="inList ? 'ion-no-margin in-list' : 'ion-no-margin'">
    <ion-card-header
      @click="() => $router.push(`/group/${id}`)"
      class="ion-no-padding"
    >
      <ion-img
        class="head-img ion-no-padding"
        :src="
          image != undefined && image.length > 0
            ? image[0].url
            : require('@//assets/img/placeholder_group.png')
        "
      ></ion-img>
      <div class="ion-padding">
        <ion-card-title>{{ name }}</ion-card-title>
        <ion-label> {{ members }} členů </ion-label>
      </div>
    </ion-card-header>

    <ion-card-content>
      <ion-button
        v-if="!isMember"
        expand="block"
        color="primary"
        @click="setMember()"
      >
        <b>Přidat se</b>
      </ion-button>
      <ion-button v-else expand="block" color="light" @click="setMember()">
        <b>Opustit skupinu</b>
      </ion-button>
    </ion-card-content>
  </ion-card>
</template>

<script>
import {
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonLabel,
  IonCardContent,
  IonButton,
  IonImg,
} from "@ionic/vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "GroupCard",
  props: {
    id: Number,
    name: String,
    members: Number,
    isMember: Number,
    image: Array,
    inList: Boolean,
  },
  data() {
    return {
      isLikedByUser: false,
    };
  },
  components: {
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonLabel,
    IonCardContent,
    IonButton,
    IonImg,
  },
  setup() {
    return {};
  },
  computed: {
    ...mapGetters({
      getActiveId: "social/getActiveId",
    }),
  },
  methods: {
    ...mapActions({
      setMembership: "groups/setMembership",
    }),
    setMember() {
      this.setMembership({
        networkId: this.getActiveId,
        groupId: this.id,
      });
    },
  },
};
</script>

<style scoped>
ion-card-subtitle {
  color: var(--ion-color-primary);
}
ion-card-title {
  margin: 8px 0px;
}

ion-img::part(image) {
  max-height: 25vh;
  object-fit: cover;
}

.in-list ion-img::part(image) {
  height: 215px;
}
</style>
